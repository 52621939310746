export type StageType = "dev" | "prod" | "test";
export type PlanType = "PREMIUM" | "AI_PLUS" | "ENGINEERING_PLUS" | "EDUCATION" | "STANDARD";
export type BillingCycle = "MONTHLY" | "YEARLY";
export function addCommaWithtoFixed(value: number) {
  return Number(value.toFixed(2)).toLocaleString();
}

export function getServicePlan(plan: PlanType) {
  switch(plan) {
    case "PREMIUM":
      return "Premium";
    case "AI_PLUS":
      return "AI +";
    case "ENGINEERING_PLUS":
      return "ENG +";
    case "STANDARD":
      return "Standard";
    case "EDUCATION":
      return "Education";
    default:
      return "";
  }
}

export function getBillingCycle(form: BillingCycle | "") {
  switch(form) {
    case "YEARLY":
      return "연간 구독";
    case "MONTHLY":
      return "월간 구독";
    default:
      return "";
  }
}