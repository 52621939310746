import React, { useEffect, useRef, useState } from 'react';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Select, TablePagination, TextField, MenuItem, Button, } from '@material-ui/core';
import useOrderLog from '../../api/useOrderLog';
import moment from 'moment';
import ServicePlanTag from '../../components/ServicePlanTag';
import { BillingCycle, getBillingCycle, PlanType } from '../../utils/parseString';

export interface OrderLogItem {
  [key: string]: OrderLogItem[keyof OrderLogItem],
  id: string,
  created_at: string,
  email: string,
  stage: string,
  writer: string,
  user_id: string,
  note?: string,
  changed_field?: string,
  prev_data?: number | string,
  next_data?: number | string,
} 

const PAGE_ROW = 20;
const header = ['user_id', 'stage', 'email', '변경 필드', '변경 전', '', '변경 후', '변경일', '작업자', '비고'];

const OrderList = () => {
  const searchTextRef = useRef<HTMLInputElement | null>(null);

  const [searchOption, setSearchOption] = useState('email');

  const [curData, setCurData] = useState<Array<OrderLogItem>>([]);
  const [orderLog] = useOrderLog();
  useEffect(() => {
    if (orderLog.Items) {
      setCurData(orderLog.Items.slice(curPage * PAGE_ROW, curPage * PAGE_ROW + PAGE_ROW));
    }
  }, [orderLog]);

  const [curPage, setCurPage] = useState(0);
  const updateData = (newPage: number) => {
    setCurPage(newPage);

    if (orderLog.Items) {
      let result = orderLog.Items;

      if (searchTextRef.current && searchTextRef.current.value.length) {
        if (searchOption === "email") {
          result = orderLog.Items.filter((item: OrderLogItem) => item[searchOption].startsWith(searchTextRef.current!.value));
        }
        else {
          result = orderLog.Items.filter((item: OrderLogItem) => item[searchOption] === searchTextRef.current!.value);
        }
      }
      setCurData(result.slice(newPage * PAGE_ROW, newPage * PAGE_ROW + PAGE_ROW)); 
    }
  }

  const renderData = (field?: string, value?: string | number) => {
    if (field === 'plan_type') {
      return (<ServicePlanTag type={value as PlanType}/>);
    }
    else if (field === 'purchase_duration_type') {
      return getBillingCycle(value as BillingCycle);
    }
    else if (value) {
      return value;
    }
    else return '-';
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <Select
          fullWidth={true}
          onChange={(event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
            setSearchOption(event.target.value as string);
          }}
          value={searchOption}>
          {['user_id', 'email'].map(column => (
            <MenuItem value={column}>{column}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <TextField id="search_field" type="search"
          inputRef={searchTextRef}
          onChange={(event) => {
            if (searchTextRef.current) {
              searchTextRef.current.value = event.target.value;
            }
          }}
          onKeyPress={(event) => {
            if (event.charCode === 13) {
              updateData(0);
            }
          }} />
      </Grid>
      <Grid item xs={2}>
        <Button variant="outlined"
          onClick={() => {
            updateData(0);
          }}> Search </Button>
      </Grid>
      <Grid item xs={12}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map(column =>
                <TableCell key={column}>{column}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              curData.map((item: OrderLogItem) => {
                const {user_id, stage, email, changed_field, prev_data, next_data, created_at, writer, note} = item;

                return <TableRow>
                  <TableCell>{user_id}</TableCell>
                  <TableCell>{stage}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{changed_field || '-'}</TableCell>
                  <TableCell style={{ textDecoration: 'line-through', color: '#C51161' }}>
                    {renderData(changed_field, prev_data)}
                  </TableCell>
                  <TableCell>➡</TableCell>
                  <TableCell style={{ color: '#4052b3' }}>
                    {renderData(changed_field, next_data)}
                  </TableCell>
                  <TableCell>
                    {moment(created_at).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell>{writer}</TableCell>
                  <TableCell>{note}</TableCell>
                </TableRow>
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[PAGE_ROW]}
          // component="div"
          count={orderLog.ScannedCount}
          rowsPerPage={PAGE_ROW}
          page={curPage}
          onPageChange={(_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            updateData(newPage);
          }}
        />
      </Grid>
    </Grid>)
}

export default OrderList;