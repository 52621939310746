import React, { Component } from 'react';


export interface ProjectListState {
  
}
export interface ProjectListProps {}

export default class ProjectList extends Component<ProjectListState, ProjectListProps> {
  render() {
    return (
      <div className="ProjectList">
        
      </div>
    )
  }
}
