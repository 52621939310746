import React, { Component } from 'react';

import { CognitoUserPool, CognitoUser, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import queryString from 'querystring';

import Axios from 'axios';

export default class extends Component {
  componentDidMount = async () => {
    const qs = queryString.parse(window.location.search.slice(1));

    const poolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    };

    const userPool = new CognitoUserPool(poolData);

    // const cognitoUser = userPool.getCurrentUser();

    // if (cognitoUser) {
    //   await new Promise((onSuccess, onFailure) => {
    //     cognitoUser.globalSignOut({
    //       onFailure,
    //       onSuccess,
    //     })
    //   });
    // }

    if (qs.code) {
      const formData = new URLSearchParams();

      formData.append("grant_type", "authorization_code");
      formData.append("client_id", process.env.REACT_APP_COGNITO_CLIENT_ID!);
      formData.append("redirect_uri", `https://${window.location.host}/signin`);
      formData.append("code", qs.code as string);

      const r = await Axios.post(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`, formData);

      console.log(r.data);

      const tokens = {
        IdToken: r.data.id_token,
        AccessToken: r.data.access_token,
        RefreshToken: r.data.refresh_token,
      };

      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken(tokens),
        AccessToken: new CognitoAccessToken(tokens),
        RefreshToken: new CognitoRefreshToken(tokens),
      });

      const cognitoUser = new CognitoUser({
        Pool: userPool,
        Username: session.getIdToken().payload['email'],
      })

      cognitoUser.setSignInUserSession(session);

      window.location.href = '/';
    }
  }

  render = () => {
    return (
      <div>Logging in...</div>
    )
  }
}