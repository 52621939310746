import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper, TableCell, ThemeProvider } from '@material-ui/core';
import { Delete, DeleteForever, MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import useDeleteOrder from '../../../hook/useDeleteOrder';
import useDeleteSubscribe from '../../../hook/useDeleteSubscribe';

interface DeleteBtnContainerProps {
  item: any;
  handleSearch: () => void;
}

const DeleteBtnContainer = (props: DeleteBtnContainerProps) => {
  const { item, handleSearch } = props;

  const { deleteOrder } = useDeleteOrder();
  const { deleteSubscribe } = useDeleteSubscribe();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    console.log(event.target, 'handle close', open)
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      console.log('return')
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <TableCell className="td delete-btn">
        <Button
          className={item.stage === 'prod' ? 'disabled' : ''}
          ref={anchorRef}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget);
            if (item.stage !== "prod") setOpen(true);
          }}>
          <MoreVert className="icon"/>
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
        {/* <ThemeProvider theme={theme}> */}
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList className="more-menu">
                <MenuItem className="del-order-menu" onClick={async (e) => {
                  handleClose(e);
                  if (window.confirm(`[${item.stage}] ${item.email}의 order를 삭제합니다.`)) {
                    await deleteOrder(item.stage, { user_id: item.user_id, base_uuid: item.base_uuid }, item.email);
                    handleSearch();
                  }
                }}>
                  <Delete className="del-icon" />Order 삭제
                </MenuItem>
                <MenuItem className="del-subscribe-menu" onClick={async (e) => {
                  handleClose(e);
                  if (window.confirm(`[${item.stage}] ${item.email}의 subscribe를 삭제합니다.`)) {
                    await deleteSubscribe({ stage: item.stage, email: item.email, user_id: item.user_id, base_uuid: item.base_uuid });
                    handleSearch();
                  }
                }}>
                  <DeleteForever className="del-icon" />Subscribe 삭제
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
          {/* </ThemeProvider> */}
        </Popper>


        {/* <Button
          disabled={item.stage === "prod"}
          variant={"outlined"}
          className={`del-btn blue ${item.stage === 'prod' ? 'disabled' : ''}`}
          onClick={() => {
            if (window.confirm(`[${item.stage}] ${item.email}의 order를 삭제합니다.`)) {
              //this.queryOrder(item);
              deleteOrder(item.stage, {user_id: item.user_id, base_uuid: item.base_uuid }, item.email);
            }
          }}>
          <div className="del-wrap">
            <Delete className="icon" />
            Order
          </div>
        </Button>
      </TableCell>
      <TableCell>
        <Button
          variant={"outlined"}
          className={`del-btn ${item.stage === 'prod' ? 'disabled' : ''}`}
          disabled={item.stage === 'prod'}
          onClick={() => {
            if (window.confirm(`[${item.stage}] ${item.email}의 subscribe를 삭제합니다.`)) {
              deleteSubscribe({stage: item.stage, email: item.email, user_id: item.user_id, base_uuid: item.base_uuid});
              // this.deleteSubscribeItem(item);
            }
          }}>
          <div className="del-wrap">
            <DeleteForever className="icon" />
            Sub
          </div>
        </Button>
        */}
      </TableCell>
    </>
  );
}

export default DeleteBtnContainer;