import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';

type ListType = Array<{ label: string | JSX.Element, value: string | undefined}>;

interface AdminSelectProps {
  value: string | JSX.Element,
  onChange: (e: any) => void;
  list: ListType,
}

const AdminSelect = (props: AdminSelectProps) => {
  const { value, onChange, list } = props;
  return (
    <AdminSelectLayout
     IconComponent={DownArrowIcon}
      value={value}
      onChange={onChange}
    >
      {list.map(item => <MenuItem style={{fontSize: '14px', fontFamily: 'Pretendard-Regular'}} key={item.value} value={item.value}>
        {item.label}</MenuItem>)}
    </AdminSelectLayout>
  );
}

export default AdminSelect;

// /#F9F9FB

const AdminSelectLayout = styled(Select)`
  background-color: #F9F9FB;
  color: #515357;
  border: 1px solid #F2F4F5;
  border-radius: 6px;
  padding: 0 8px 0 4px;
  font-family: 'Pretendard-Regular' !important;
  font-size: 14px;
  .MuiSelect-icon {
    width: 18px;
  }

  &:hover, &:before {
    border-bottom: 1px solid transparent;
  }
`;