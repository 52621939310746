import Axios from 'axios';
import App from '../App';
import { StageType } from '../utils/parseString';
import useAddChangeLog from './useAddChangeLog';
import useDeleteOrder from './useDeleteOrder';

const useDeleteSubscribe = () => {
  const { addLog } = useAddChangeLog();
  const {deleteOrder} = useDeleteOrder();

  const deleteSubscribe = async (userInfo: { stage: StageType, user_id: string, email: string, base_uuid: string }) => {
    const { stage, email, user_id, base_uuid } = userInfo;
    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;

    const body = {
      user_id: userInfo.user_id
    };

    // todo order 삭제 후 subscribe 삭제

    await deleteOrder(stage, { user_id: user_id, base_uuid: base_uuid }, email, true)
      .then(async () => {
      await Axios.delete(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-user-subscribe-${stage}/_delete`,
      {
        data: body,
        headers: {
          Authorization: `Bearer ${JWT}`,
        }
      })
    })
      .then(() => {
        addLog('', '', { user_id, stage, email }, 'SUBSCRIBE 삭제');
        alert('SUBSCRIBE가 삭제되었습니다.')
      })
      .catch((e) => {
        alert(`SUBSCRIBE 삭제 실패 ${e}`);
      })
  }

  return { deleteSubscribe };
}

export default useDeleteSubscribe;