import Axios from 'axios';
import App from '../App';
import { StageType } from '../utils/parseString';
import useAddChangeLog from './useAddChangeLog';

const useDeleteOrder = () => {
  const { addLog } = useAddChangeLog();

  const deleteOrder = async (stage: StageType, body: { [key: string]: string }, email: string, unLog?: boolean) => {
    if (!body.base_uuid) {
      alert('ORDER가 없습니다.');
      return;
    }
    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;
    await Axios.delete(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-order-${stage}/_delete`,
      {
        data: body,
        headers: {
          Authorization: `Bearer ${JWT}`,
        }
      })
      .then(() => {
        if (!unLog) {
          addLog('', '', {user_id: body.user_id, stage, email}, 'ORDER 삭제');
          alert('ORDER가 삭제되었습니다.')  
        }
      })
      .catch((e) => {
        alert(`ORDER 삭제 실패 ${e}`);
      })
  }

  return { deleteOrder };
}

export default useDeleteOrder;