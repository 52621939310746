import Axios from 'axios';
import { useEffect } from 'react';
import App from '../App';

const useGetOrder = () => {
  useEffect(() => {
    getData('', '', '')
  }, [])

  const getData = async (stage: string, userId: string, baseUUID: string) => {
    if (stage && userId && baseUUID) {
      const data = await Axios.post(`https://platform-data-${stage}.buildit.co.kr/api/DDB/platform-buildit-order-${stage}/_search`, {
        "user_id": userId,
        "base_uuid": baseUUID,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${App.accessJWT}`,
        },
      }).then(v => {
        return v.data.result;
      })
        .catch(e => {
          console.log(e);
        })
      return data;
    }
  }
  return [getData];
}

export default useGetOrder;