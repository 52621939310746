import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import '../css/components/DatePicker.scss';
interface DatePickerProps {
  value?: ParsableDate,
  onChange: (e: any) => void;
}

const DatePicker = (props: DatePickerProps) => {
  const { onChange, value } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className='DatePicker'
        disableToolbar
        variant="inline"
        onChange={onChange}
        value={value}
        placeholder="설정되지않음"
        margin="normal"
        format="yyyy-MM-dd"
        KeyboardButtonProps={{ 'aria-label': "change date" }}
        InputProps={{ disableUnderline: true }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;