import { getAccessToken } from "../App";
import { useEffect, useState } from 'react';
import Axios from "axios";
import { OrderLogItem } from "../pages/OrderLog/OrderLog";

type DataType = {
  Items: Array<OrderLogItem>,
  Count: number,
  ScannedCount: number,
}

const useOrderLog = () => {
  const [data, setData] = useState<DataType>({ Items: [], Count: 0, ScannedCount: 0 });

  const getOrderLog = async () => {
    const JWT = await getAccessToken('dev');
    const response = await Axios.get(`https://platform-data-dev.buildit.co.kr/api/DDB/platform-buildit-plan-admin-log/_scan`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    if (response.data.message === "ok") {
      const { Items, Count, ScannedCount } = response.data.result;
      const newData = Items.sort((a: any, b: any) =>
        (a.created_at > b.created_at) ? -1 : ((a.created_at > b.created_at) ? 1 : 0));
      setData({ Items: newData, Count, ScannedCount });
    }
  }

  useEffect(() => {
    getOrderLog();
  }, []);

  return [data];
}

export default useOrderLog;