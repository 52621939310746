import React from 'react';
import { StageType } from '../utils/parseString';
import styled from 'styled-components';

interface StageChipProps {
  stage: StageType;
}

const StageChip = (props: StageChipProps) => {
  const { stage } = props;
  return (<StageChipLayout stage={stage}>{stage}</StageChipLayout>);
}

export default StageChip;

const StageChipLayout = styled.div<{ stage: StageType }>`
  width: 40px;
  height: 25px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  ${({ stage }) => {
    switch (stage) {
      case "prod":
        return {
          background: '#FEE0DD',
          color: '#E16563',
        };
      case "dev":
      default:
        return {
          background: '#D9EAFD',
          color: '#4E90D4',
        };
    }
  }};
`;