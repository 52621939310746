import Axios from 'axios';
import { useState } from 'react';

import App from '../App';
import { StageType } from '../utils/parseString';

const useGetSubscribeData = () => {
  const [loading, setLoading] = useState(false);

  const fetchAllSubscribe = async (body: Array<any>, stage: StageType) => {
    if (body.length === 0) return [];
    
    setLoading(true);
    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;
    const result = await Axios.post(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-user-subscribe-${stage}/_search`,
      body,
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      });
    setLoading(false);

    if (result.status === 200) {
      const resultData = result.data.result.map((item: any) => {
        const {user_id, plan_finished_at, plan_started_at, plan_changed_at, plan_type, available_count, base_uuid, max_count, status, purchase_duration_type} = item;
        return {
          user_id,
          plan_finished_at,
          plan_started_at,
          plan_changed_at,
          plan_type,
          available_count,
          base_uuid,
          max_count,
          status,
          purchase_duration_type
        };
      });
      return resultData;
    }
    return [];
  }

  return {
    getSubscribeData: fetchAllSubscribe,
    loading,
  };
}

export default useGetSubscribeData;