import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';

interface SearchFormProps {
  onSearch: () => void;
  option: string;
  inputRef: any;
  handleSearchOption: (value: string) => void;
}

const SearchForm = (props: SearchFormProps) => {
  const { onSearch, option, inputRef, handleSearchOption } = props;
  
  return (
    <>
      <Grid item xs={2}>
        <Select fullWidth={true} onChange={(event: any) => {
          handleSearchOption(event.target.value);
        }} 
        value={option}>
          {["id", "email", "name", "contact_number", "company"].map(column => (
            <MenuItem key={column} value={column}>{column}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <TextField
          placeholder='검색어 입력'
          inputRef={inputRef}
          id="search_field" type="search"
          onKeyPress={(event) => {
            if (event.charCode === 13 && inputRef.current) {
              onSearch();
           //*   this.setState({ search_string: value, current_page: 0, need_update: true });
            }
          }} />
      </Grid>
      <Grid item xs={2}>
        <Button variant="outlined" onClick={() => {
          onSearch();
       //*   this.setState({ search_string: value, current_page: 0, need_update: true });
        }}> Search </Button>
      </Grid>
    </>);
};

export default SearchForm;