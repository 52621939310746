import React, { useState } from 'react';
import { Grid, Table, TableRow, TableCell, Select, TextField, MenuItem, Button } from '@material-ui/core';


import useGetOrder from './hook/useGetOrder';

const OrderList = (props: any) => {
  const [userId, setUserId] = useState('');
  const [baseUUID, setBaseUUID] = useState('');
  const [stage, setStage] = useState('dev');
  const [data, setData] = useState<any>(null);
  const [getData] = useGetOrder();

  return (
    <div className="OrderList">
      <Grid container spacing={3}>
      <Grid item xs={2}>
        <Select 
          fullWidth={true}
          value={stage}
          onChange={(e) => setStage(e.target.value as string)}
        >
          <MenuItem value={"dev"}>dev</MenuItem>
          <MenuItem value={"test"}>test</MenuItem>
          <MenuItem value={"prod"}>prod</MenuItem>
        </Select>
      </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth={true}
            type="serach"
            placeholder='user_id'
            value={userId}
            onChange={(e) => { setUserId(e.target.value) }} />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth={true}
            type="serach"
            placeholder='base_uuid'
            value={baseUUID}
            onChange={(e) => { setBaseUUID(e.target.value) }} />
        </Grid>
        <Grid item xs={2}>
          <Button 
            variant="outlined" 
            onClick={() => {
              getData(stage, userId, baseUUID).then(v => {
                setData(v);
              });
            }}>Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div>
            {data ? <Table size="small" aria-label="simple table">
              {data && Object.keys(data).map((v: string) =>
                <TableRow>
                  <TableCell>{v}</TableCell>
                  <TableCell className="value">{JSON.stringify(data[v])}</TableCell>
                </TableRow>
              )}
            </Table> : <div>user_id, base_uuid를 입력하세요<span><span role="img" aria-label="search">🔍</span></span></div>}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderList;