import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

const UserTableHead = () => {
  return (<TableHead>
    <TableRow>
      {
        ['ID', 'Stage', '이메일', '중복계정 허용', '플랜 타입', '기간 타입', '상태', '남은 횟수', '최대 횟수', '이용 기간', '플랜 변경일', 'Actions']
          .map(column => <TableCell key={column} className="th">{column}</TableCell>)
      }
    </TableRow>
  </TableHead>);
}

export default UserTableHead;