import Axios from 'axios';
import { v4 as uuid4 } from "uuid";
import App from '../App';

const useAddChangeLog = () => {

  const addLog = async (field: string, value: string | number | boolean, userInfo: any, note?: string) => {
    const { user_id, stage, email } = userInfo;
    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;

    const body = {
      id: uuid4(),
      changed_field: field,
      user_id,
      email,
      stage,
      prev_data: field ? userInfo[field].toString() : "",
      next_data: value,
      writer: App.session.email,
      created_at: new Date().toISOString(),
      note,
    }
    await Axios.post(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-plan-admin-log/_insert`, body, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      }
    })
    .catch((e) => {
      console.log(e);
    })
  }

  return { addLog };
}

export default useAddChangeLog;