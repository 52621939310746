import { BillingCycle } from "./parseString";

export function generateBaseUUID(userId: string, orderId: string, index: number | undefined = undefined) {
  const hmac = require('crypto').createHmac('sha1', `${userId};${orderId};PAYMENT_SUCCESS${undefined === index ? '' : index}`).digest();
  const base62 = require("base-x")('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
  return base62.encode(hmac);
}

export function getPlanFinishDate(startDate: string, cycle: BillingCycle): string {
  const approved_at = new Date(startDate);
  const addDate = cycle === 'MONTHLY' ? new Date(approved_at.getFullYear(), approved_at.getMonth() + 1, 0).getDate() : 365;
  const endDay = new Date(approved_at.setDate(approved_at.getDate() + addDate - 1)).toISOString();
  return endDay;
}

