import Axios from 'axios';
import App from '../App';
import { StageType } from '../utils/parseString';

const useGetUserInfo = () => {

  const getUserInfo = async (data: Array<any>) => {
    const promises = data.map(({ user_id, stage }) => fetchUserInfo(stage, user_id).then((res) => res));
    return Promise.all(promises);
  }

  const fetchUserInfo = async (stage: StageType, userName: string) => {
    const JWT = App.DEV_TOKEN;
    const res = await Axios.get(`https://platform-data-dev.buildit.co.kr/api/admin/user/${stage}/${userName}`, {
      headers: {
        'Cache-Control': 'private,no-cache,no-store',
        'Authorization': `Bearer ${JWT}`,
      }
    }).catch(() => ({data: {}}));
    return res.data;
  }

  return { getUserInfo };
}

export default useGetUserInfo;