import Axios from 'axios';

const useGetLoginDate = () => {
  // 마지막 로그인 날짜 가져오기
  const getLastLogin = async (data: Array<any>) => {
    return Promise.all(data.map((user: any) => {
      const {user_id} = user;
      return Axios.post('https://platform-kibana.1011.co.kr/buildit-login-log/_search', {
        "query": {
          "query_string": {
            "query": `user_id: ${user_id}`
          },
        },
        "from": 0,
        "size": 1,
        "sort": [{ "TimeISOString": "desc" }],
      });
    }))
    .then((res) => {
      return res.map(result => {
        if (result.status === 200 && result.data.hits.hits.length) {
          return result.data.hits.hits[0]._source;
        }
        else {
          return {};
        }
      })
    });
  }
  return { getLastLogin };
}

export default useGetLoginDate;