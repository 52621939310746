import React, { useEffect, useRef, useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import './UserList.scss';
import SearchForm from './component/SearchForm';
import useGetUserList from '../../hook/useGetUserList';
import useGetAccessToken from '../../hook/useGetAccessToken';

import App from '../../App';

import UserListContainer from './component/UserListContainer';
import useFetchProdUserSubscribe from '../../hook/useFetchProdUserSubscribe';

require('moment/locale/ko');
moment.locale('ko');

const UserList = () => {
  // token
  const { token: DEV_TOKEN } = useGetAccessToken({ stage: 'dev' });
  const { token: PROD_TOKEN } = useGetAccessToken({ stage: 'prod' });

  useEffect(() => {
    App.DEV_TOKEN = DEV_TOKEN;
    App.PROD_TOKEN = PROD_TOKEN;
  }, [DEV_TOKEN, PROD_TOKEN]);


  // searchForm
  const [searchOption, setSearchOption] = useState('email');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputText, setInputText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  // 
  const [data, setData] = useState<Array<any>>([]);
  const [curPage, setCurPage] = useState(0);

  // 초기 화면 prod 계정만 보여주기
  const {
    loading: prodLoading,
    data: prodData,
    total: prodTotal,
    onPageChange: onProdPageChange,
    refetch: refetchProdUser
  } = useFetchProdUserSubscribe();

  // 검색 결과 보여주기
  const {
    loading: searchLoading,
    data: searchData,
    total: searchTotal,
    refetch: searchUser,
    onPageChange: onSearchPageChange
  } = useGetUserList({ tableName: `platform-buildit-user` });

  const handleSearch = () => {
    setInputText(inputRef.current!.value);
    // 검색
    const inputVal = inputRef.current!.value;
    if (!inputVal) {
      refetchProdUser();
    }
    else {
      const queryString = `${searchOption}.keyword: *${inputVal}*`;
      searchUser(queryString, 10, 0);
    }
    setCurPage(0);
  }

  useEffect(() => {
    console.log(searchData, 'search data')
    setData(searchData);
    setTotalCount(searchTotal);
  }, [searchData]);

  useEffect(() => {
    setData(prodData);
    setTotalCount(prodTotal);
  }, [prodData])

  return (<>
    <Grid container spacing={3} className="UserList">
      <SearchForm
        option={searchOption}
        inputRef={inputRef}
        handleSearchOption={(value) => setSearchOption(value)}
        onSearch={() => { handleSearch() }} />
      {
        (searchLoading || prodLoading) ?
          <Grid item xs={12}>
            <CircularProgress />
          </Grid> :
          <UserListContainer
            data={data}
            totalCount={totalCount}
            curPage={curPage}
            handleSearch={handleSearch}
            handlePageChange={(page: number) => {
              setCurPage(page);
              if (inputText) {
                onSearchPageChange(page);
              }
              else {
                onProdPageChange(page);
              }
            }}
          />
      }

    </Grid>
  </>);
}

export default UserList;