import Axios from 'axios';
import App from '../App';
import useAddChangeLog from './useAddChangeLog';

type UserInfo = {
  user_id: string,
  stage: string,
  // 변경하는 필드 명, 값
  fieldName?: string,
  fieldValue?: string,
}
const useEditSubscribeData = (config?: { [key: string]: any }) => {
  const { addLog } = useAddChangeLog();

  const getKeyValue = (nextData: any) => {
    const keys = Object.keys(nextData);
    let key = '';
    let value: number | string = '';
    if (keys.length) {
      if (keys.length === 1) {
        key = keys[0];
        value = nextData[key];
      }
      else {
        key = 'purchase_duration_type';
        value = nextData[key];
      }
    }
    return { key, value };
  }

  const editField = async (nextData: { [key: string]: number | string }, userInfo: UserInfo) => {
    const { user_id, stage } = userInfo;
    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;
    const body = {
      "key": {
        user_id,
      },
      "data": nextData
    }
    await Axios.put(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-user-subscribe-${stage}/_put`,
      body,
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    )
      .then(() => {
        alert('변경되었습니다.');
        const { key, value } = getKeyValue(nextData);
        addLog(key, value, userInfo); // 변경 기록 추가
        config && config.onSuccess && config.onSuccess();
      })
      .catch((e) => {
        alert(`실패 ${e}`);
        //window.location.reload();
      });
  }

  return { editField };
}

export default useEditSubscribeData;