import React, { Component, useState } from 'react';
import './App.css';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';
import { Drawer, IconButton, SwipeableDrawer } from '@material-ui/core';

import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
// } from "react-router-dom";


import Signin from './Signin';

import AWS from 'aws-sdk';
import PointList from './PointList';
import ProjectList from './ProjectList';
//import ParcelInfoList from './ParcelInfoList';
import OrderList from './OrderList';
import Axios from 'axios';
import UserList from './pages/UserList/UserList';
import { StageType } from './utils/parseString';
import OrderLog from './pages/OrderLog/OrderLog';

const BrowserRouter = require("react-router-dom").BrowserRouter;
const Route = require("react-router-dom").Route;
const Link = require("react-router-dom").Link;
const Switch = require("react-router-dom").Switch;
interface AppState {
  open: boolean;
  loginChecked: boolean;
}

interface RenderProps {
  open: boolean;
  handleDrawerOpen: (open: boolean) => void;
}



const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `100%`,
      marginLeft: drawerWidth,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

const Render = (props: RenderProps) => {
  const classes = useStyles();

  let { open, handleDrawerOpen } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerOpen(!open)}
            edge="start"
            className={clsx(classes.menuButton, open)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            BUILDIT Admin Page
          </Typography>
        </Toolbar>
      </AppBar>
      {/* swipe */}
      <Drawer
        anchor={"left"}
        open={open}
        onClose={() => { handleDrawerOpen(false) }}
      >  
        <List>
          <a href='/' style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary="User List"></ListItemText>
            </ListItem>
          </a>
          <a href='/orderLog' style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary="Order Log"></ListItemText>
            </ListItem>
          </a>
          
        </List>
  
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <BrowserRouter>
          <div className={classes.root}>
            <Switch>            {/* 사용자 목록 */}
              <Route exact path="/">
                <UserList />
              </Route>
              {/* admin 변경 내역 */}
              <Route exact path="/orderLog">
                <OrderLog />
              </Route>
              {/* <Route path='/pointList'>
              <PointList />
            </Route> */}
              <Route exact path='/orderList'>
                <OrderList />
              </Route>
              <Route exact path='/signin'>
                <Signin />
              </Route>
              <Route exact path='/projectList'>
                <ProjectList />
              </Route>
            </Switch>

          </div>
        </BrowserRouter>

      </main>
    </div>
  );
}

export default class App extends Component<{}, AppState> {
  static AWS: typeof AWS;
  static session: any;
  static accessJWT: string;
  static DEV_TOKEN: string;
  static PROD_TOKEN: string;
    

  state: AppState = {
    loginChecked: false,
    open: false,
  };

  componentDidMount = async () => {
   
    if (window.location.pathname !== '/signin') {
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
      };

      const userPool = new CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();
  
      if (!cognitoUser) {
        window.location.href = `${process.env.REACT_APP_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=https://${window.location.host}/signin&response_type=code`;
      } else {
        cognitoUser.getSession((e: Error, session: CognitoUserSession) => {
          AWS.config.region = 'us-east-1';
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
            Logins: {
              [`cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: session.getIdToken().getJwtToken(),
            },
          })
  
          App.AWS = AWS;
          App.session = session.getIdToken().payload;
//          App.accessJWT = session.getAccessToken().getJwtToken();
          this.setState({
            loginChecked: true,
          });
        });
        

      }
    } else {
      this.setState({
        loginChecked: true,
      });
    }
  }

  render = () => {
    if (this.state.loginChecked) {
      return (
        <Render {...this.state} handleDrawerOpen={(open: boolean) => {
          this.setState({
            open,
          });
        }} />
      )
    } else {
      return <div>Loading...</div>
    }
  }
} 

export const getAccessToken = async (stage: StageType) => {
  const username = process.env[`REACT_APP_AUTH_USERNAME_${stage === 'prod' ? 'PROD' : 'DEV'}`] || '';
  const password = process.env[`REACT_APP_AUTH_PASSWORD_${stage === 'prod' ? 'PROD' : 'DEV'}`] || '';

  const auth = {
    username,
    password,
  };

  return await Axios.post(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/Auth/`, {}, {
    auth,
  }).then(res => res.data.access_token)
    .catch(e => {
      alert(`${e}\n${e.response}\n access token error`);
      window.location.reload();
    });
}