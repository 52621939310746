import Axios from 'axios';
import { useState } from 'react';
import useGetSubscribeData from './useGetSubscribeData';
import useGetUserInfo from './useGetUserInfo';
import useGetLoginDate from './userCognitoInfo/useGetLoginDate';

interface useFetchProps {
  tableName: string;
  config?: { [key: string]: string };
  enabled?: boolean; // 자동 실행
}

// search user list
const useGetUserList = (props: useFetchProps) => {
  const { tableName } = props;  
  const {getUserInfo} = useGetUserInfo();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);

  const [total, setTotal] = useState(0); // 총 개수
  const [totalData, setTotalData] = useState<Array<any>>([]); // 총 데이터

  const { getSubscribeData } = useGetSubscribeData();
  const { getLastLogin } = useGetLoginDate();

  const fetch = async (queryString: string, size: number, from: number) => {
    setLoading(true);
    const body = {
      "query": {
        "bool": {
          "must": [
            {
              "exists": {
                "field": "stage"
              },
            },
            {
              "query_string": {
                "query": queryString
              }
            }
          ]
        }
      },
      "size": 100,
      "from": from,
    };
    Axios.post(`https://platform-kibana.1011.co.kr/${tableName}/_search`, body)
      .then(async (res) => {
        // _id가 dev, prod, test로 시작하는지 체크 (중복 결과 제거)
        const data = res.data.hits.hits.filter((item: any) => /^dev|prod|test/i.test(item._id));

        const parsedData = data.map((item: any) => {
          const { email, id, stage, company, contact_number, name, date } = item._source;
          console.log(date, 'date')
          return {
            email,
            user_id: id,
            stage,
            company,
            contact_number,
            name,
            date,
          }
        });
        //총 검색 결과
        setTotalData(parsedData);
        setTotal(parsedData.length);

        const slicedData = parsedData.slice(0, 10);
        await getSubscribe(slicedData);
      });
  }

  const getSubscribe = async (slicedData: Array<any>) => {
    // get subscribe
    const devId = slicedData.filter((item: any) => item.stage === "dev")
      .map((item: any) => { return { user_id: item.user_id } });
    const prodId = slicedData.filter((item: any) => item.stage === "prod")
      .map((item: any) => { return { user_id: item.user_id } });

    const result: Array<any> = [];
    let unSubscribeData = slicedData;
    await Promise.all([getSubscribeData(devId, "dev"), getSubscribeData(prodId, "prod")])
      .then((res) => {
        
       
          // res: prod, dev 유저 데이터 array
        for (const data of res) {
          if (data.length) {
            const findData = slicedData.find(item => item.user_id && item.user_id === data[0].user_id);
         
            if (findData) {
              const {stage} = findData;
              const newData = data.map((item: any) => {
                const {email, name, company, contact_number, date} = slicedData.find(sliceItem => sliceItem.user_id === item.user_id);
                unSubscribeData = unSubscribeData.filter(unSubItem => unSubItem.user_id !== item.user_id);
                return { ...item, stage, email, name, company, contact_number, date }
              });
              result.push(...newData);
            }
          }
       
          // else {
          //   result.push();
          // }
        }
      });
        // todo subscribe가 없는 경우
       if (unSubscribeData.length) {
        result.push(...unSubscribeData);
       } 
      setUserInfo(result);
    // if (result.length === 0) {
    //   setUserInfo(result);
    // }
    // else {
    //   setUserInfo(result);
    // }
    setLoading(false);
  }

  // 최근 로그인 (마지막 단계)
  const setUserInfo = async (data: Array<any>) => {
    // 마지막 로그인
    const loginResult = await getLastLogin(data);
    // 가입일, 멀티세션
    const userResult = await getUserInfo(data);

    for (let i = 0; i < data.length; i++) {
      const {user_id} = data[i];
      const findUser = userResult.find((item) => item.Username === user_id);
      const findLogin = loginResult.find((item) => item.user_id === user_id);
      const {UserCreateDate, MultiSession} = findUser;

      data[i] = { 
        ...data[i], 
        registered_date: UserCreateDate, 
        last_login: findLogin?.TimeISOString,
        multi_session: MultiSession
      };
    }
    setData(data);
  }

  // pagination
  const onPageChange = (page: number) => {
    const slicedData = totalData.slice(page * 10, page * 10 + 10);
    // get subscribe data
    getSubscribe(slicedData);
  };

  return { loading, data, refetch: fetch, total, onPageChange };
}

export default useGetUserList;