import Axios from 'axios';
import { useEffect, useState } from 'react';
import App from '../App';
import useGetUserInfo from './useGetUserInfo';
import useGetLoginDate from './userCognitoInfo/useGetLoginDate';

// user list 초기화면
const useFetchProdUserSubscribe = () => {
  const [data, setData] = useState<Array<any>>([]); // 페이지 별 데이터
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0); // 총 개수
  const [totalData, setTotalData] = useState([]); // 총 데이터
  const [isDoneSubscribeUser, setIsDoneSubscribeUser] = useState(false);
  const {getUserInfo} = useGetUserInfo();
  const { getLastLogin } = useGetLoginDate();

  useEffect(() => {
    setLoading(true);
    getProdUserSubscribe();  
  }, []);

  // subscribe를 갖는 전체 prod 유저 찾는 함수
  const getProdUserSubscribe = async () => {
    const result = await Axios.post('https://platform-kibana.1011.co.kr/platform-buildit-user-subscribe-prod/_search', {
      "query": {
        "match_all": { } // search all
      },
      "from": 0,
      "size": 10000,
      "sort": [{ "plan_started_at": "desc" }],
    });

    if (result.status === 200) {
      const data = result.data.hits.hits.map((item: any, idx: number) => {
        return item._source;
      });

      setTotal(result.data.hits.total);
      setTotalData(data);
      setIsDoneSubscribeUser(true);
    }
  }

  useEffect(() => {
    if (isDoneSubscribeUser) {
      // 데이터 slice
      onPageChange(0);
      // subscribe 없는 prod 유저 찾기
      getUnsubscribeUser();
      setIsDoneSubscribeUser(false);
    }
  }, [isDoneSubscribeUser]);

  // 현재 페이지에 나타나는 데이터들의 user 테이블 정보를 찾는 함수
  const getDDB = async (keys: Array<{ id: string }>, callback: Function) => {
    // user table 정보 가져오기
    const ddb = new App.AWS.DynamoDB.DocumentClient({
      region: "ap-northeast-2"
    });
    const params = {
      RequestItems: {
        'platform-buildit-user': {
          Keys: keys,
        },
      }
    }
    ddb.batchGet(params, (err, response) => {
      if (err) {
        throw new Error('table "platform-buildit-user" error!');
      }
      else {
        const result = response!.Responses!['platform-buildit-user'];
        callback(result);
      }
    });
  }

  // 구독 없는 prod 유저 
  const getUnsubscribeUser = async () => {

    const idArr = totalData.map((item: any) => item.user_id);
    const mustNot = [
      {
        "exists": {
          "field": "paying_type", // 중복 계정 제외
        },
      },    
    ];

    if (idArr.length > 0 && idArr[0]) {
      mustNot.push({ "terms": { "id": idArr } } as any);
    }

    const result = await Axios.post('https://platform-kibana.1011.co.kr/platform-buildit-user/_search', {
      "query": {
        "bool": {
          "must": [
            {  "query_string": {
              "query": "stage: prod"
            }},
            { "exists": { "field": "stage" }, }, // stage필드가 존재
          ],
          "must_not": mustNot
        },
      },
      "from": 0,
      "size": 100,
      "sort": [{ "email.keyword": "asc" }], 
    });
    if (result.status === 200) {
      setTotal(total + result.data.hits.total);
      const mergedData = totalData.concat(result.data.hits.hits.map((item: any) => item._source));
      setTotalData(mergedData);
    }
  }

  // pagination
  const onPageChange = async (page: number) => {
    if (totalData.length) {
      const pagingData = totalData.slice(page * 10, page * 10 + 10);
      const keys = pagingData.map((item: any) => { return { id: item.user_id } }).filter(item => item.id);
      if (keys.length) {
        getDDB(keys, async (userResult: any) => {
          const data = pagingData.map((item: any) => {
            const find = userResult.find((user: any) => user.id === item.user_id);
            if (find) {
              return { ...item, ...find };
            }
            else {
              return { ...item };
            }
          });
          if (data.length) {
            setUserInfo(data);
          }
        });
      }
      else {
        // subscribe없는 데이터  
        const pagingData = totalData.slice(page * 10, page * 10 + 10)
          .map((item: any) => { return { ...item, user_id: item.id }; });
        await setUserInfo(pagingData);
      }
    }

    setLoading(false);
  }

  // 최근 로그인 / 가입일 / 멀티세션허용
  const setUserInfo = async (data: Array<any>) => {
    // 마지막 로그인
    const loginResult = await getLastLogin(data);
    // 가입일, 멀티세션
    const userResult = await getUserInfo(data);

    for (let i = 0; i < data.length; i++) {
      const {user_id} = data[i];

      const findUser = userResult.find((item) => item.Username === user_id);
      const findLogin = loginResult.find((item) => item.user_id === user_id);
      let registeredDate = '';
      let multiSession = false;

      if (findUser) {
        const {UserCreateDate, MultiSession} = findUser;
        registeredDate = UserCreateDate;
        multiSession = MultiSession;
      }

      data[i] = { ...data[i], 
        registered_date: registeredDate, 
        last_login: findLogin?.TimeISOString,
        multi_session: multiSession
      };
    }
    setData(data);
    
  }

  return {
    onPageChange,
    refetch: getProdUserSubscribe,
    loading,
    data,
    total,
  };
}

export default useFetchProdUserSubscribe;