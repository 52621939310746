import { useEffect, useState } from 'react';
import { getAccessToken } from '../App';
import { StageType } from '../utils/parseString';

interface useGetAccessTokenProps {
  stage: StageType;
}

const useGetAccessToken = (props: useGetAccessTokenProps) => {
  const { stage } = props;
  const [token, setToken] = useState('');

  useEffect(() => {
    getAccessToken(stage)
      .then((res) => {
        setToken(res);
      });
  }, [stage]);

  return { token };
}

export default useGetAccessToken;