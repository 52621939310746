import { generateBaseUUID, getPlanFinishDate } from '../utils/generateBaseuuid';
import { v4 as uuid4 } from "uuid";
import { PlanType, StageType } from '../utils/parseString';
import Axios from 'axios';
import App from '../App';

const useOrder = (config?: {onSuccess?: () => void}) => {

  const fetchOrder = async (userId: string, stage: StageType, planType: PlanType) => {
    
    // 새 플랜 주문
    const today = new Date().toISOString();
    const endDate = getPlanFinishDate(today, 'MONTHLY');

    const orderId = uuid4();
    const baseUUID = generateBaseUUID(userId, orderId);

    // subscribe 추가
    const subscribeData = {
      user_id: userId,
      available_count: 10,
      base_uuid: baseUUID,
      billing_key: "",
      cancel_backup_finished_at: "",
      card_company: "",
      card_number: "",
      card_owner_type: "",
      count_timestamp: "",
      first_plan_started_at: today,
      last_purchased_at: today,
      max_count: 10,
      plan_changed_at: "",
      plan_finished_at: endDate,
      plan_started_at: today,
      plan_type: planType,
      purchase_duration_type: "MONTHLY",
      status: "구독중"
    };

    const JWT = stage === 'dev' ? App.DEV_TOKEN : App.PROD_TOKEN;
    await Axios.post(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-user-subscribe-${stage}/_insert`, subscribeData, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    }).catch((e) => {
      alert(`에러 발생 ${e}`);
      window.location.reload();
    });

    // order 추가
    const orderData = {
      // DDB 키값
      user_id: userId,
      // 변수
      base_uuid: baseUUID,
      amount: {
        balanceAmount: 0,
        suppliedAmount: 0,
        totalAmount: 0,
        vat: 0
      },
      method: "카드",
      order_approved_at: today,
      order_id: orderId,
      order_name: planType,
      order_type: "PAYMENT",
      payment_key: "",
      purchase_duration_type: "MONTHLY",
      receipt: {
        url: ""
      },
      status: "성공",
      title: "구독 시작"
    };

    await Axios.post(`https://platform-data${stage === "prod" ? "" : `-${stage}`}.buildit.co.kr/api/DDB/platform-buildit-order-${stage}/_insert`, orderData, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    })
    .then(() => {
      alert('플랜이 변경됐습니다.')
    }).catch((e) => {
      alert(`에러 발생 ${e}`);
      window.location.reload();
    });
    config && config.onSuccess && config.onSuccess();

  }

  return {fetchOrder};
}

export default useOrder;