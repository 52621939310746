import { Checkbox, Button, Grid, Paper, Table, TableBody, TableCell, TablePagination, TableRow, Tooltip } from '@material-ui/core';
import { Edit, FileCopy, Refresh } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import AdminSelect from '../../../components/AdminSelect';
import DatePicker from '../../../components/DatePicker';
import ServicePlanTag from '../../../components/ServicePlanTag';
import useEditSubscribeData from '../../../hook/useEditSubscribeData';
import useOrder from '../../../hook/useOrder';
import { getPlanFinishDate } from '../../../utils/generateBaseuuid';
import { BillingCycle, getBillingCycle, StageType } from '../../../utils/parseString';
import DeleteBtnContainer from './DeleteBtnContainer';
import UserTableHead from './UserTableHead';
import Clipboard from 'react-clipboard.js';
import StageChip from '../../../components/StageChip';
import useUpdateMultiSession from '../../../hook/useUpdateMultiSession';

const planTypeList = [
  { label: "플랜 미적용", value: "undefined" },
  { label: <ServicePlanTag type={"EDUCATION"} />, value: 'EDUCATION' },
  { label: <ServicePlanTag type={"STANDARD"} />, value: "STANDARD" },
  { label: <ServicePlanTag type={"ENGINEERING_PLUS"} />, value: "ENGINEERING_PLUS" },
  { label: <ServicePlanTag type={"AI_PLUS"} />, value: "AI_PLUS" },
  { label: <ServicePlanTag type={"PREMIUM"} />, value: "PREMIUM" }
];

const cycleList = [
  { label: "기간 미적용", value: "undefined" },
  { label: "월간 구독", value: "MONTHLY" },
  { label: "연간 구독", value: "YEARLY" }
];

const statusList = [
  { label: '상태 미적용', value: "undefined" },
  { label: "구독중", value: "구독중" },
  { label: '구독정지', value: "구독정지" },
  { label: '구독정지예정', value: "구독정지예정" },
  { label: '구독형태변경예정', value: "구독형태변경예정" },
];

interface UserListContainerProps {
  //inputValue: string;
  totalCount: number; // 전체 개수
  data: Array<any>; // 10개씩 보여주는 데이터
  curPage: number;
  handleSearch: () => void;
  handlePageChange: (page: number) => void; // 페이지 변경
}

const UserListContainer = (props: UserListContainerProps) => {
  const {data, totalCount, curPage, handlePageChange, handleSearch} = props;
  const [rowPerPage, setRowPerPage] = useState(10);

  // DDB 수정
  const { editField } = useEditSubscribeData({ onSuccess: handleSearch });
  const { fetchOrder } = useOrder({ onSuccess: handleSearch });
  const { handleMultiSession: setMultiSession } = useUpdateMultiSession({ onSuccess: handleSearch });

  // 기간 변경
  const handleBillingCycle = (userInfo: any, cycle: BillingCycle) => {
    const { plan_started_at } = userInfo;
    const endDate = getPlanFinishDate(plan_started_at || '', cycle);
    editField({
      plan_finished_at: endDate,
      purchase_duration_type: cycle,
    }, userInfo);
  }

  // 횟수 변경
  const handleCount = (defaultVal: number, field: string, userInfo: any) => {
    let title = "현재 횟수를 입력하세요. (숫자)";
    let key = "available_count";
    if (field === 'max_count') {
      title = '최대 횟수를 입력하세요. (숫자)';
      key = 'max_count'
    }
    const value = prompt(title, `${defaultVal}`);
    if (value) {
      if (isNaN(Number(value))) {
        alert('숫자를 입력해주세요.');
        return;
      }
      editField({ [key]: Number(value) }, userInfo);
    }
  }

  // 멀티 세션 허용
  const handleMultiSession = (item: any) => {
    const { stage, email, multi_session } = item;
    const msg = `[${stage}] ${email}의 중복계정 허용을 ${multi_session ? 'true에서 false' : 'false에서 true'}로 변경하시겠습니까?${multi_session ? '\n\n※ 접속중인 모든 세션이 강제 로그아웃 됩니다.' : ''}`;

    const confirm = window.confirm(msg);
    if (confirm) {
      setMultiSession(item, !multi_session);
    }
  }

  return (<Grid item xs={12}>
    <Paper>
    <Table size="small" aria-label="simple table" className="user-table">
      <UserTableHead />
      <TableBody>
        {
          data.map((item: any) => {
            return <TableRow key={item.user_id}>
              <TableCell className="td">
                <div className="wrap-id">
                  <div className="ellipsis">user_id: {item.user_id}</div>
                  <Clipboard className="clipboard" data-clipboard-text={item.user_id}>
                    <Tooltip title="user_id 복사"><FileCopy className="copy-icon" /></Tooltip>
                  </Clipboard>
                </div>
                <div className="wrap-id">
                  <div className="ellipsis">base_uuid: {item.base_uuid}</div>
                  <Clipboard className="clipboard" data-clipboard-text={item.base_uuid}>
                    <Tooltip title="base_uuid 복사"><FileCopy className="copy-icon" /></Tooltip>
                  </Clipboard>
                </div>
              </TableCell>
              <TableCell className="td"><StageChip stage={item.stage}/></TableCell>
              <TableCell className="td">
                <div className="dropdown-title">
                  <div className="email">{item.email}</div>
                  <div>{item.name || '-'}</div>
                  <div className="dropdown-content">
                    <div>연락처: {item.contact_number || '-'}</div>
                    <div>업체명: {item.company || '-'}</div>
                    <div>가입일: {item.registered_date ? moment(item.registered_date).format('YYYY-MM-DD') : '-'}</div>
                    <div>최근 로그인: {item.last_login ? moment(item.last_login).format('YYYY-MM-DD HH:mm') : '-'}</div>
                  </div>
                </div>
              </TableCell>
              {/* 중복계정 */}
              <TableCell className="td">
                <Checkbox
                  checked={item.multi_session}
                  onChange={() => handleMultiSession(item)} />
              </TableCell>
              {/* 플랜 타입 */}
              <TableCell className="td">
                <AdminSelect
                  value={item.plan_type || "undefined"}
                  onChange={(e) => {
                    const isConfirmed: boolean = window.confirm(`[${item.stage}] ${item.email}의 플랜 타입을 \n"${item.plan_type}"에서 "${e.target.value}"로 변경합니다.`);
                    if (isConfirmed) {
                      if (!item.base_uuid || item.status === "구독정지" || item.status === "구독정지예정") {
                        fetchOrder(item.user_id, item.stage, e.target.value);
                      }
                      else {
                        editField({ 'plan_type': e.target.value }, item);
                      }
                    }
                  }}
                  list={planTypeList}
                />
              </TableCell>
              {/* 기간 타입 */}
              <TableCell className="td">
                {!item.plan_type ? <div>-</div> :
                  (item.status !== "구독정지" && item.status !== "구독정지예정") ?
                    <AdminSelect
                      value={item.purchase_duration_type}
                      onChange={(e) => {
                        const isConfirmed = window.confirm(`[${item.stage}] ${item.email}의 기간 타입을 \n"${item.purchase_duration_type}"에서 "${e.target.value}"로 변경합니다.`);
                        if (isConfirmed) {
                          handleBillingCycle(item, e.target.value);
                        }
                      }}
                      list={cycleList}
                    />
                    : <span>{getBillingCycle(item.purchase_duration_type)}</span>
                }
              </TableCell>
              {/* status */}
              <TableCell className="td">
                {
                  !item.plan_type ? <div>-</div> :
                    <AdminSelect
                      value={item.status}
                      onChange={(e) => {
                        const isConfirmed = window.confirm(`[${item.stage}] ${item.email}의 상태를 \n"${item.status}"에서 "${e.target.value}"로 변경합니다.`);
                        if (isConfirmed) {
                          if (item.status === "구독정지" && e.target.value === "구독중") {
                            fetchOrder(item.user_id, item.stage, item.plan_type);
                          }
                          else {
                            editField({ 'status': e.target.value }, item);
                          }
                        }
                      }}
                      list={statusList}
                    />
                }
              </TableCell>
              {/* 하루 이용 가능 횟수 */}
              <TableCell className="td">
                {!item.plan_type ? <div>-</div> :
                  <>
                    <span style={{ marginRight: "5px" }}>{item.available_count}</span>
                    {
                      (item.status !== "구독정지" && item.status !== "구독정지예정") &&
                      <Button
                      className="edit-count-btn"
                      //  variant={"outlined"}
                        size='small' color='primary'
                        style={{ width: "30px", minWidth: "30px" }}
                        onClick={() => {
                          handleCount(item.available_count, 'available_count', item);
                        }}><Edit style={{ width: '16px' }} /></Button>
                    }
                  </>
                }
              </TableCell>
              {/* 최대 횟수 */}
              <TableCell className="td">
                {!item.plan_type ? <div>-</div> :
                  <>
                    <span style={{ marginRight: "5px" }}>{item.max_count}</span>
                    {
                      (item.status !== "구독정지" && item.status !== "구독정지예정") &&
                      <Button
                      className="edit-count-btn"
                        size='small' color='primary'
                     //   variant={"outlined"}
                        style={{ width: "30px", minWidth: "30px" }}
                        onClick={() => {
                          handleCount(item.max_count, 'max_count', item);
                        }}><Edit style={{ width: '16px' }} /></Button>
                    }
                  </>
                }
              </TableCell>
              {/* 플랜 시작일 */}
              <TableCell className="td">
                {!item.plan_type ? <div style={{ fontSize: '16px' }}>-</div> :
                  (item.status !== "구독정지" && item.status !== "구독정지예정") ?
                    <div className="period">
                      <DatePicker
                        value={item.plan_started_at === undefined ? null : item.plan_started_at}
                        onChange={(e: any) => {
                          const date = e.toISOString();
                          const isConfirmed = window.confirm(`[${item.stage}] ${item.email}의 plan_started_at을 \n"${item.plan_started_at.split('T')[0]}"에서 "${date.split('T')[0]}"로 변경합니다.`);
                          if (isConfirmed) {
                            editField({ 'plan_started_at': date }, item);
                          }
                        }} />
                      <div className='wave'>~</div>
                      <DatePicker
                        value={item.plan_finished_at === undefined ? null : item.plan_finished_at}
                        onChange={(e: any) => {
                          const date = e.toISOString();
                          const isConfirmed = window.confirm(`[${item.stage}] ${item.email}의 plan_finished_at을 \n"${item.plan_finished_at.split('T')[0]}"에서 "${date.split('T')[0]}"로 변경합니다.`);
                          if (isConfirmed) {
                            editField({ 'plan_finished_at': date }, item);
                          }
                        }}
                      />
                    </div> :
                    <div style={{ fontSize: '16px' }}>{item.plan_started_at.split('T')[0]} ~ {item.plan_finished_at.split('T')[0]}</div>
                }
              </TableCell>
              {/* 플랜 변경일 */}
              <TableCell className="td">
                {!item.plan_type ? <div>-</div> :
                  (item.status !== "구독정지" && item.status !== "구독정지예정") ?
                    <>
                      <DatePicker
                        value={item.plan_changed_at || null}
                        onChange={(e: any) => {
                          const date = e.toISOString();
                          const isConfirmed = window.confirm(`[${item.stage}] ${item.email}의 plan_changed_at을 \n"${item.plan_changed_at?.split('T')[0]}"에서 "${date.split('T')[0]}"로 변경합니다.`);
                          if (isConfirmed) {
                            editField({ 'plan_changed_at': date }, item);
                          }
                        }}
                      />
                      <Tooltip title="플랜 변경일 초기화">
                      <Button
                        className="reset-btn"
                        style={{ width: "30px", minWidth: "30px" }}
                        onClick={() => {
                          if (window.confirm(`[${item.stage}] ${item.email}의 plan_changed_at을 \n"${item.plan_changed_at}"에서 ""로 변경합니다.`)) {
                            editField({ 'plan_changed_at': '' }, item);
                          }
                        }}>
                        <Refresh style={{ width: '16px' }} />
                      </Button>
                      </Tooltip>
                    </> :
                    <span>{item.plan_changed_at?.split('T')[0]}</span>
                }
              </TableCell>
              {/* 삭제 버튼 */}
              <DeleteBtnContainer 
              item={item} 
                handleSearch={handleSearch}
              />
            </TableRow>
          })
        }
      </TableBody>
    </Table>
    <TablePagination
      className="pagination"
      rowsPerPageOptions={[10]} // 25, 100
      count={totalCount}
      rowsPerPage={rowPerPage}
      page={curPage}
      onPageChange={(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        handlePageChange(newPage);
      }}
      onRowsPerPageChange={(event: any) => {
        setRowPerPage(event.target.value);
      }}
    />
    </Paper>
  </Grid>);
}

export default UserListContainer;
