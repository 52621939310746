import React from 'react';


import '../css/ServicePlanTag.scss';
import { getServicePlan, PlanType } from '../utils/parseString';


interface ServicePlanTagProps {
  type: PlanType;
}

const ServicePlanTag = (props: ServicePlanTagProps) => {
  const { type } = props;
  return (
    <div className={`ServicePlanTag ${type}`}>{getServicePlan(type)}</div>
  );
}

export default ServicePlanTag;
