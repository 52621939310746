import App from '../App';
import useAddChangeLog from './useAddChangeLog';

const useUpdateMultiSession = ({ onSuccess }: { onSuccess?: Function }) => {
  const JWT = App.DEV_TOKEN;
  const { addLog } = useAddChangeLog();

  const handleMultiSession = async (userInfo: any, isAllow: boolean) => {
    const { stage, user_id: userName } = userInfo;
    fetch(`https://platform-data-dev.buildit.co.kr/api/admin/user/multisession/${stage}/${userName}`,
      {
        method: isAllow ? 'PUT' : 'DELETE',
        headers: {
          'Cache-Control': 'private,no-cache,no-store',
          'Authorization': `Bearer ${JWT}`,
        }
      })
      .then(() => {
        alert('변경되었습니다.');
        onSuccess && onSuccess();
        addLog('multi_session', isAllow.toString(), userInfo);
      })
      .catch((e) => alert(`실패 ${e}`));
  }
  return { handleMultiSession };
}
export default useUpdateMultiSession;